<template>
  <div class="search">
    <el-input placeholder="搜索您喜欢的内容" v-model="text" class="input-with-select" @keydown.enter.native="search">
      <el-select v-model="select" slot="prepend" placeholder="请选择">
        <el-option label="素材" value="1"></el-option>
        <el-option label="画板" value="2"></el-option>
        <el-option label="用户" value="3"></el-option>
        <el-option label="我的素材" value="4"></el-option>
        <el-option label="我的画板" value="5"></el-option>
      </el-select>
      <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
    </el-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      select: '1',
      text: '',
    }
  },
  methods: {
    search() {
      this.$message("接口未开发");
      return false;
      this.$router.push({
        path: '/collectSearch',
        query: { q: this.select, by: this.text }
      });
    },
  },
  created() {
    if (this.$route.query.by) {
      this.text = this.$route.query.by;
    }
  },
}
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
  ::v-deep .el-input-group--append {
    .el-input-group__prepend {
      min-width: 120px;
    }
  }
}
</style>