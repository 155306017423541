<template>
<div class="draw_board">
  <div class="board_wapper" v-if="data.length > 0">
    <el-row :gutter="10">
      <el-col :span="span" v-for="(board, index) in data" :key="index">
        <div class="board" @click="boardDetails(board.id)">
          <div class="bodyer" ref="board">
            <div class="cover">
              <el-image :src="board.preview[0] ? board.preview[0].url : ''" fit="cover"></el-image>
            </div>
            <div class="small_cover">
              <el-row :gutter="5" type="flex" justify="space-between">
                <el-col :span="8">
                  <div class="s_cover">
                    <el-image :src="board.preview[1].url" fit="cover" v-if="board.preview[1]"></el-image>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="s_cover">
                    <el-image :src="board.preview[2].url" fit="cover" v-if="board.preview[2]"></el-image>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="s_cover">
                    <el-image :src="board.preview[3].url" fit="cover" v-if="board.preview[3]"></el-image>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="board_name">
              <h3>{{board.title}}</h3>
              <h4>{{board.like_num}} 喜欢</h4>
            </div>      
          </div>
          <div class="footer">
            <div class="sure" @click.stop="">点 赞</div>
            <div class="preview">预 览</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <div class="no_board" v-else>找不到任何画板</div>
</div>
</template>

<script>
export default {
  props: {
    span: {
      type: Number,
      default: 4
    },
    data: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    boardDetails(id) {
      this.$router.push({
        path: '/collectBoard',
        query: { id }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.draw_board {
  user-select: none;
  .board_wapper {
    .board {
      box-sizing: border-box;
      position: relative;
      height: 350px;
      padding: 10px;
      margin-bottom: 10px;
      background: rgba(255,255,255,.5);
      box-shadow: 0 1px 3px rgba(0,0,0,.02), 0 4px 8px rgba(0,0,0,.02);
      border-radius: 3px;
      overflow: hidden;
      transition: transform .15s ease-in-out,box-shadow .15s ease-in-out;
      cursor: pointer;
      &:hover {
        background: #fff;
        box-shadow: 0 1px 3px rgba(0,0,0,.02), 0 16px 32px -4px rgba(0,0,0,.17);
        transform: translateY(-3px);
      }
      .bodyer {
        height: 290px;
        margin-bottom: 10px;
        position: relative;
        .cover {
          height: 215px;
          margin-bottom: 5px;
          .el-image {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
          }
        }
        .small_cover {
          height: 70px;
          .s_cover {
            box-sizing: border-box;
            height: 70px;
            overflow: hidden;
            box-shadow: inset 0 0 2px rgba(0,0,0,.2);
            .el-image {
              box-sizing: border-box;
              width: 100%;
              height: 100%;
            }
          }
        }
        .board_name {
          position: absolute;
          left: 10px;
          top: 10px;
          color: #fff;
          > h3 {
            font-size: 14px;
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-shadow: 0 1px 3px rgba(0,0,0,.5);
          }
          > h4 {
            margin-top: 3px;
            height: 14px;
            line-height: 14px;
            font-size: 10px;
            font-weight: 400;
            text-shadow: 0 1px 3px rgba(0,0,0,.5);
          }
        }
      }
      .footer {
        height: 30px;
        text-align: center;
        .sure {
          float: left;
          box-sizing: border-box;
          width: 65%;
          height: 30px;
          line-height: 30px;
          background: #ededed;
          border: 1px solid #d9d9d9;
          &:hover {
            background: #d9d9d9;
            color: #444;
          }
        }
        .preview {
          float: right;
          box-sizing: border-box;
          width: 30%;
          height: 30px;
          line-height: 30px;
          background: #fff;
          border: 1px solid #d9d9d9;
          &:hover {
            background: #d9d9d9;
            color: #444;
          }
        }
      }
    }
  }
  .no_board {
    width: 100%;
    text-align: center;
    padding-top: 150px;
    color: #999;
    font-size: 1.2em;
  }
}
</style>