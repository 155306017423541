<template>
  <div class="container" ref="waterfall">
    <div class="search_box">
      <collect-search></collect-search>
    </div>
    <div class="collection_discovery">
      <!-- 分类 -->
      <div class="classify" :class="spreadOut ? 'more' : ''">
        <div class="classify_l">
          <span class="collect" :class="[{'active': displayPage == 0}]" @click="changeDisplayPage(0)">素材</span>
          <span class="board" :class="[{'active': displayPage == 1}]" @click="changeDisplayPage(1)">画板</span>
        </div>
        <div class="classify_c">
          <span class="tag" :class="index === classifyIndex ? 'active' : ''" v-for="(tag, index) in classify" :key="index" @click="changeClassify(index)">
            {{tag.title}}
          </span>
        </div>
        <div class="classify_r">
          <div class="zhankai" :class="spreadOut ? 'spread_out' : ''">
            <i class="iconfont iconspread-line" @click="spreadOut = !spreadOut"></i>
          </div>
        </div>
      </div>
      <!-- 排序 -->
      <div class="sorts_box">
        <div class="sorts">
          <div class="sort" :class="[{'active': collectQuery.ord == 0}]" @click="changeOrd(0)">最新素材</div>
          <div class="sort" :class="[{'active': collectQuery.ord == 1}]" @click="changeOrd(1)">最多收藏</div>
          <div class="sort" :class="[{'active': collectQuery.ord == 2}]" @click="changeOrd(2)">最多点赞</div>
        </div>
      </div>
      <!-- 素材 -->
      <my-waterfall :data="collectData" @loadmore="loadmore" v-if="displayPage == 0">
      </my-waterfall>
      <no-more :show="noMore && collectQuery.page != 1" v-if="displayPage == 0"></no-more>
      <!-- 画板 -->
      <draw-board :data="boardData" v-if="displayPage == 1"></draw-board>
    </div>
  </div>
</template>

<script>
import CollectSearch from "@/components/CollectSearch.vue";
import MyWaterfall from '@/components/MyWaterfall';
import DrawBoard from '@/components/DrawBoard';
import NoMore from "@/components/NoMore.vue";
export default {
  name: 'CollectionCenter',
  components: {
    CollectSearch,
    MyWaterfall,
    DrawBoard,
    NoMore,
  },
  data() {
    return {
      displayPage: 0,  // 0:素材 1:画板
      collectData: [],
      collectQuery: {cat_id: '', username: '', ord: 0, title: '', use: 0, page: 1},
      boardData: [],
      boardQuery: {title: '', use: 0, username: '', cat_id: ''},
      classify: [],
      spreadOut: false,
      classifyIndex: 0,
      noMore: false,
    }
  },
  methods: {
    changeDisplayPage(num) {
      this.noMore = false;
      this.displayPage = num;
    },
    getCollectData() {  // 采集数据
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/list',
        method: 'GET',
        params: {
          cat_id: this.collectQuery.cat_id,
          username: this.collectQuery.username,
          ord: this.collectQuery.ord,
          title: this.collectQuery.title,
          use: this.collectQuery.use,
          page: this.collectQuery.page,
        },
      }).then(res => {
        if (res.code == 200) {
          if (res.data.res.length == 20) {
            this.noMore = false;
            this.collectData = this.collectData.concat(res.data.res ? res.data.res : []);
          } else {
            this.noMore = true;
            this.collectData = this.collectData.concat(res.data.res ? res.data.res : []);
          }
        }
      })
    },
    getBoardData() {  // 画板数据
      this.$service.DEFAULT({
        url: '/auth/ppt/board/list',
        method: 'GET',
        params: {
          title: this.boardQuery.title,
          use: this.boardQuery.use,
          username: this.boardQuery.username,
          cat_id: this.boardQuery.cat_id,
        },
      }).then(res => {
        if (res.code == 200) {
          if (res.data.res.length == 20) {
            this.noMore = false;
            this.boardData = this.boardData.concat(res.data.res ? res.data.res : []);
          } else {
            this.noMore = true;
            this.boardData = this.boardData.concat(res.data.res ? res.data.res : []);
          }
        }
      })
    },
    loadmore() {   // 加载更多
      this.collectQuery.page += 1;
      this.getCollectData();
    },
    getClassify() { // 全部分类
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/catlist',
        method: 'GET',
      }).then(res => {
        if (res.code == 200) {
          this.classify = res.data ? res.data : [];
          this.classify.unshift({id: '', title: '全部'});
        }
      })
    },
    changeClassify(index) { // 分类筛选
      this.classifyIndex = index;
      this.collectQuery.cat_id = this.boardQuery.cat_id = this.classify[this.classifyIndex].id;
      if (this.displayPage == 0) {
        this.collectQuery.page = 1;
        this.collectData = [];
        this.getCollectData();
      } else {
        this.boardData = [];
        this.getBoardData();
      }
    },
    changeOrd(num) { // 采集排序
      this.collectQuery.ord = num;
      this.collectQuery.page = 1;
      this.collectData = [];
      this.getCollectData();
    },
  },
  created() {
    this.getCollectData();
    this.getBoardData();
    this.getClassify();
  },
}
</script>

<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  width: 100%;
  min-width: 1200px;
  min-height: calc(100vh - 70px);
  background: #f5f5f5;
  padding-top: 20px;
  .search_box {
    width: 90%;
    margin: 0 auto;
  }
  .sorts_box {
    width: 90%;
    margin: 0.75em auto 0;
    user-select: none;
    .sorts {
      text-align: center;
      .sort {
        display: inline-block;
        padding: 0 .75em .1em;
        font-size: 1.2em;
        color: #999;
        cursor: pointer;
        &:hover {
          color: #d71345;
        }
        &.active {
          color: #d71345;
          border-bottom: 3px solid#d71345;
        }
      }
    }
  }
  .collection_discovery {
    width: 90%;
    margin: 0 auto;
    .classify {
      box-sizing: border-box;
      width: 100%;
      padding: 10px 15px;
      margin-top: 0.75em;
      background: #fff;
      box-shadow: 0 1px 3px 0 rgba(0,0,0,.02), 0 4px 8px 0 rgba(0,0,0,.02);
      border-radius: 2px;
      display: flex;
      transition: all 360ms;
      max-height: 52px;
      overflow: hidden;
      &.more {
        max-height: 300px;
      }
      .classify_l {
        height: 30px;
        margin-right: 60px;
        border: 1px solid #ddd;
        border-radius: 4px;
        cursor: pointer;
        .collect {
          line-height: 30px;
          display: inline-block;
          padding: 0 6px;
          font-size: 14px;
        }
        .board {
          line-height: 30px;
          display: inline-block;
          padding: 0 6px;
          font-size: 14px;
        }
        .active {
          background: #f5f5f5;
          cursor: default;
        }
      }
      .classify_c {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .tag {
          margin: 0 15px;
          font-size: 14px;
          cursor: pointer;
          display: flex;
          align-items: center;
          &:hover {
            color: #d71345;
          }
        }
        .active {
          color: #d71345;
        }
      }
      .classify_r {
        cursor: pointer;
        margin-left: 60px;
        .zhankai {
          padding: 3px 6px;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 14px;
          i {
            margin-top: 2px;
            display: block;
            transition: all 360ms;
          }
        }
        .spread_out {
          background-color: #ededed;
          .iconspread-line {
            transform: rotateZ(-180deg);
          }
        }
      }
    }
  }
}
</style>