<template>
  <div class="no_more" v-if="show">
    <div class="text">没有更多了</div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  }
}
</script>

<style lang="scss" scoped>
.no_more {
  width: 100%;
  border-top: 6px double #ccc;
  text-align: center;
  .text {
    margin: 0.8em 0;
    font-weight: 550;
  }
}
</style>