<template>
  <waterfall class="my_waterfall" :col="col" :data="data" @loadmore="loadmore" v-if="data.length > 0">
    <div class="waterfall_item" v-for="(item, index) in data" :key="index">
      <!-- 前置插槽 -->
      <div class="waterfall_append" v-if="$slots.append && index === 0">
        <slot name="append"></slot>
      </div>
      <!-- 私有采集 -->
      <div class="waterfall_private" v-if="!item.url">
        <div class="private_content" :style="{ background: bgColor(index) }">
          <i class="iconfont iconsuo"></i>
          <div class="my_waterfall__lock"><span>该采集待公开</span></div>
        </div>
      </div>
      <!-- 公开采集 -->
      <div class="waterfall_public" v-if="item.url">
        <div class="public_content__image" @click="viewDetails(item.id)">
          <div class="inner_box">
            <img class="image" :src="item.url" alt="图片加载失败" />
          </div>
          <div class="operation collect">
            <el-button type="danger" size="small" @click.stop>收藏</el-button>
          </div>
          <div class="operation sure_wapper">
            <slot name="buttonTR" :scope="item">
              <div class="sure" @click.stop>
                <i class="iconfont iconaixin-kuai"></i>
              </div>
            </slot>
          </div>
        </div>
        <div class="public_content__desc">
          <p>
            {{item.info}}
          </p>
        </div>
        <div class="public_content__stats">
          <div class="like">
            <i class="el-icon-s-comment"></i>
            <span> {{item.coll_num}}</span>
          </div>
          <div class="comment">
            <i class="iconfont iconaixin-kuai"></i>
            <span> {{item.like_num}}</span>
          </div>
        </div>
        <div class="public_content__author">
          <div class="avatar" @click.stop="personalCenter">
            <el-avatar size="large" :src="item.picture" v-if="item.picture"></el-avatar>
            <img class="default_avatar" src="../assets/img/title_icon.png" v-else>
          </div>
          <div class="inner">
            <span @click.stop="userCollect">{{item.username}}</span> 
            采集到 
            <span @click.stop="userBoard(item.id)">{{item.cat_title}}</span>
          </div>
        </div>
      </div>
    </div>
  </waterfall>
  <div style="padding:150px 0;" v-else>
    <div style="width:100%;text-align:center;color:#999;font-size:1.2em">找不到任何素材</div>
  </div>
</template>

<script>
export default {
  name: 'MyWaterfall',
  props: {
    column: {
      type: Number,
      default: 6
    },
    data: {
      type: Array,
      default: function () {
        return []
      },
    }
  },
  data() {
    return {
      lockBgColor: ['#8dfff7', '#ecc9c7', '#d1cfc0', '#abf9d0', '#c49ac7', '#93dd9b', '#c49ac7', '#b6e284', '#9adbf9', '#70d2db'],
    }
  },
  computed: {
    col() {
      return this.column;
    },
  },
  methods: {
    bgColor(index) {  // 私有采集随机背景色
      let i = index % this.lockBgColor.length > this.lockBgColor.length ? 1 : index % this.lockBgColor.length;
      return this.lockBgColor[i];
    },
    loadmore() {
      this.$emit('loadmore');
    },
    viewDetails(id) {
      let routeObj = this.$router.resolve({
        path: '/CollectDetails',
        query: { pins: id }
      })
      window.open(routeObj.href, '_blank');
    },
    userCollect(id) {
      this.$message("接口未开发");
      return false;
      this.$router.push({
        path: '/collectUser',
        query: { u: id }
      })
    },
    userBoard(id) {
      this.$message("接口未开发");
      return false;
      this.$router.push({
        path: '/CollectBoard',
        query: { u: id }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.my_waterfall {
  width: 100%;
  padding-top: 20px;
  .waterfall_item {
    box-sizing: border-box;
    padding: 5px;
    margin-bottom: 10px;
    .waterfall_append,
    .waterfall_private,
    .waterfall_public {
      box-sizing: border-box;
      width: 100%;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 1px 3px rgba(0,0,0,.02), 0 4px 8px rgba(0,0,0,.02);
      transition: transform .15s ease-in-out,box-shadow .15s ease-in-out;
      position: relative;
    }
    .waterfall_append {
      margin-bottom: 10px;
    }
    .waterfall_private,
    .waterfall_public {
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 1px 3px rgba(0,0,0,.02), 0 16px 32px -4px rgba(0,0,0,.17);
      }
    }
    .waterfall_private {
      padding-bottom: 100%;
      cursor: pointer;
      .private_content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #8dfff7;
        border-radius: inherit;
        &:hover {
          .my_waterfall__lock {
            display: block;
          }
        }
        .iconsuo {
          font-size: 3em;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .my_waterfall__lock {
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: rgba(229,229,229,.75);
          > span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            font-size: 14px;
            color: #444;
            font-size: 1.5em;
            white-space: nowrap;
          }
        }
      }
    }
    .waterfall_public {
      &:hover {
        .public_content__image {
          .operation {
            display: block;
          }
        }
      }
      .public_content__image {
        cursor: pointer;
        position: relative;
        .image {
          display: block;
          width: 100%;
          min-height: 100px;
          max-height: 800px;
          border-radius: 6px 6px 0 0;
          object-fit: cover;
        }
        .operation {
          display: none;
        }
        .collect {
          position: absolute;
          left: 10px;
          top: 10px;
        }
        .sure_wapper {
          position: absolute;
          right: 10px;
          top: 10px;
          .sure {
            padding: 6px 10px;
            background-color: #eee;
            border-radius: 3px;
            &:hover {
              background-color: #ccc;
            }
          }
        }
        .edit {
          position: absolute;
          right: 10px;
          top: 10px;
        }
        .resemble {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
      }
      .public_content__desc {
        > p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          padding: 0 16px;
          margin: 10px 0;
          word-wrap: break-word;
          font-size: 1rem;
          color: #444;
        }
      }
      .public_content__stats {
        padding: 0 15px;
        margin: 10px 0;
        color: #8c7e7e;
        .like {
          display: inline-block;
          margin-right: 10px;
        }
        .comment {
          display: inline-block;
          margin-right: 10px;
          .iconaixin-kuai {
            font-size: 14px;
            color: #ed1941;
          }
        }
      }
      .public_content__author {
        padding: 10px 15px;
        color: #999;
        border-top: 1px solid #f2f2f2;
        display: flex;
        align-items: center;
        .avatar {
          margin-right: 10px;
          cursor: pointer;
          .default_avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
        .inner {
          font-size: 14px;
          > span {
            color: #9e7e6b;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>